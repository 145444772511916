.awardBox {
  width: 100% !important;
  border: 8px solid #5cdc3c;
}
.cutEdge {
  background: #fff;
  border-bottom: 8px solid #5cdc3c;
  height: 57px;
  left: -27px;
  position: absolute;
  top: -40px;
  transform: rotate(-47deg);
  width: 27px;
}
.awardBox::before,
.awardBox::after {
  border-style: solid;
  content: ' ';
  height: 0;
  position: absolute;
  right: auto;
  top: auto;
  width: 0;
}
.awardBox:before {
  border-color: #5cdc3c transparent transparent #5cdc3c;
  border-width: 20px;
  bottom: -41px;
  left: -8px;
}
.awardBox:after {
  border-color: #fff transparent transparent #fff;
  border-width: 12px;
  bottom: -22px;
  left: 0;
}
